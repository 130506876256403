import { memo } from "react";
import loadable, { type LoadableComponent } from "@loadable/component";
import { type ApexOptions } from "apexcharts";

// const Chart: LoadableComponent<ApexOptions> = lazy(
//   () => import("react-apexcharts")
// );
const Chart: LoadableComponent<ApexOptions> = loadable(
  () => import("react-apexcharts"),
  {
    ssr: false,
    resolveComponent: (components: any) => components.default as any,
  }
);

export default memo(Chart);
