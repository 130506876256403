export default {
  greeting: "Hallo",
  navItems: {
    home: "Home",
    statistics: "Team Statistieken",
    playerPerformance: "Speler prestaties",
    matchPerformance: "Wedstrijd prestaties",
    playerPerformanceOverview: "Overzicht",
    playerPerformanceGoals: "Doelpunten",
    playerPerformanceAssists: "Assists",
    playerPerformanceAwards: "Awards",
    minutesPlayed: "Minuten gespeeld",
    playerPerformanceDistanceCovered: "Afstand afgelegd",
    attendance: "Aanwezigheid",
    team: "Team leden",
    opponents: "Tegenstanders",
    scheduling: "Schedule",
    media: "Media",
    settings: "Instellingen",
    match: "Wedstrijden",
    training: "Trainingen",
    matchAttendance: "Wedstrijd aanwezigheid",
    trainingAttendance: "Training aanwezigheid",
    matchesUpcoming: "Wedstrijden",
    trainingUpcoming: "Trainingen",
    premium: "Elevate",
  },
  tempMessageLinkLabel: "Meer info",
  tempMessage:
    "Probeer <2>Homebase Beta gratis</2> terwijl we werken aan <2>Mingle Essentials</2>",
  watchMingleReplayLabel: "Check Replay",
  introReplayText: "Mingle replay nu uit - log in en bekijk de jouwe!",
  title: "Log in op Homebase",
  introText:
    "Homebase is een desktop app for Mingle Sport power users. Meer stats, media en team management opties.",
  introTextSub: "Nieuw? Maak een account aan.",

  labelEmail: "Email",
  placeholderInputEmail: "name@mail.com",
  labelPassword: "Wachtwoord",
  placeholderInputPassword: "Wachtwoord",
  linkForgotPassword: "Wachtwoord vergeten?",
  buttonLogin: "Log in",
  buttonSignInApple: "Log in met Apple",
  buttonSignInGoogle: "Log in met Google",
  buttonSignUpApple: "Registreer met Apple",
  buttonSignUpGoogle: "Registreer met Google",
  protectedByReCaptcha: "Deze site is beveiligd door reCAPTCHA en het Google ",
  protectedByReCaptchaPrivacy: "Privacybeleid",
  protectedByReCaptchaPrivacyTerms: "Servicevoorwaarden",
  protectedByReCaptchaPrivacyTermsApply: "zijn van toepassing.",
  Goalkeeper: "Keeper",
  Centreback: "Centrale verdediger",
  Leftback: "Linker verdediger",
  Rightback: "Rechter verdediger",
  DefensiveMidfielder: "Verdedigende middenvelder",
  CentralMidfielder: "Centrale middenvelder",
  LeftMidfielder: "Linker middenvelder",
  RightMidfielder: "Rechter middenvelder",
  AttackingMidfielder: "Aanvallende middenvelder",
  LeftWinger: "Linker vleugelspeler",
  RightWinger: "Rechter vleugelspeler",
  Striker: "Aanvaller",

  loginLoading: "Controleren van inlog gegevens en laden teams...",
  titleModalTeamIsArchived: "Team is gearchiveerd",
  bodyModalTeamIsArchived:
    "Dit team is gearchiveerd en er kunnen daarom geen wedstrijden em/of trainingen meer worden aangemaakt. Je kunt het team weer activeren bij de instellingen van je team.",

  titleModalAdminRights: " rechten nodig",
  bodyModalAdminRights:
    "Je hebt geen rechten om deze feature te gebruiken. Vraag je team admin voor de rechten.",
  titleModalSeasonNotAvailable: "Nog geen volgend seizoen beschikbaar",

  titleModalBoostLevelRights: "Team boost nodig",
  bodyModalBoostLevelRights:
    "Je heb een team boost nodig om deze feature te gebruiken. Koop een team boost in de app.",
  linkLabelBoostLevelRights: "Wat is een  team boost?",

  bodyModalSeasonNotAvailable:
    "Er is nog geen volgend seizoen beschikbaar. Contact support om te vragen wanneer het volgende seizoen beschikbaar is.",
  buttonModalSeasonNotAvailableContact: "Contact support",
  titleModalTransferConfirm: "Maak nieuw team voor het volgende seizoen?",
  bodyModalTransferConfirm:
    "Je staat op het punt om een nieuw team te maken voor het volgende seizoen. Het huidige seizoen en team blijft gewoon bestaan.",
  buttonModaTransferConfirmCreate: "Bevestig",
  titleModalLogout: "Weet je zeker dat je uit wil loggen?",
  buttonModalLogoutLabel: "Log uit",

  onBoardingPages: {
    labelButtonSignUp: "Registreer",
    orSignUpSignUp: "Of registreer-up met e-mail",
    startTitle: "Maak jouw team aan",
    welcomeTitle: "Welkom bij Mingle Sport",
    welcomeBody1: "Een betere app voor je voetbalteam.",
    welcomeBody2:
      "Track en vergelijk stats. Deel match content. Manage jouw team.",
    startSubTitle: "Maak jouw team aan",
    labelDateOfBirth: "Geboortedatum",
    labelPreferredPosition: "Favoriete positie",
    roleTitle: "Wat is jouw rol?",
    roleBody: "Selecteer de belangrijkste als je meerdere rollen hebt.",
    followPlayersTitle: "Volg je favoriete spelers",
    followPlayersBody: "Download de app en zoek je favoriete team of speler",
    followLearnMoreButtonLabel: "Hoe volg ik een team?",
    followPlayerLearnMoreButtonLabel: "Hoe volg ik een speler?",
    teamSetupTitle: "Maak jouw team aan",
    joinTeamTitle: "Join een team",
    joinTeamsBody: "Download de app en voer de team code van jouw team in",
    joinTeamLearnMoreButtonLabel: "Hoe join ik een team?",

    joinTeamButtonLabel: "Join",
    setUpTeamTitle: "Team aanmaken",
    setUpTeamButtonLabel: "Maak team aan",
    setUpTeamFanButtonLabel: "Ik ben een fan en ik wil een team volgen",

    teamSetupBody: "Plan wedstrijden. Maak opstellingen. Manage jouw team.",
    whatToImproveTitle: "Wat wil je verbeteren?",
    whatToImproveBody: "Selecteer zoveel je wilt.",
    whatToImproveBlock1Title: "Team motivatie",
    whatToImproveBlock2Title: "Team aanwezigheid",
    whatToImproveBlock3Title: "Fan Engagement",
    whatToImproveBlock4Title: "Performance",
    teamLevelTitle: "Wat is het teamniveau?",
    teamLevelBody: "We zijn een app voor ieder team.",
    labelTeamGender: "Geslacht",
    labelTeamAgeGroup: "Leeftijdsgroep",
    labelTeamLevel: "Teamniveau",
    labelSelectTeamGender: "Selecteer geslacht",
    labelSelectTeamAgeGroup: "Selecteer leeftijdsgroep",
    labelSelectTeamLevel: "Selecteer teamniveau",
    teamLocationCountryLabel: "Land",
    teamLocationTitle: "Waar spelen jullie de thuiswedstrijden?",
    teamLocationBody:
      "Je kunt je locatie veranderen voor ieder wedstrijd en training.",
    whatSportTitle: "Welke sport doe je?",
    whatSportBody: "Selecteer de sport waarvoor je een team aanmaakt",
    inviteShareTitle: "Nodig spelers uit",
    inviteShareBody:
      "Nodig spelers uit om deel te nemen aan jouw team. Je kunt ze ook uitnodigen via de team link.",
    matchSetupTitle: "Jouw wedstrijdopstelling",
    matchSetupBody: "Selecteer je voorkeur voor de wedstrijdopstelling",
    labelPlayers: "Spelers",
    labelPeriods: "Periodes",
    shareTeamInviteTitle: "Deel team link",
    shareTeamInviteButtonLabel: "Deel team link",
    addPlayersTitle: "Voeg spelers toe",
    addPlayersButtonLabel: "Voeg spelers toe",
    invitePlayersTitle: "Voeg spelers toe",
    invitePlayersBody:
      "Nodig spelers uit om deel te nemen aan jouw team. Je kunt ze ook uitnodigen via de team link.",
    shareTitle: "Deel team link",
    shareBody:
      "Spelers, staf en teamleden (ouders of anderen) kunnen lid worden van het team via de link 'Lid worden van team'.",
    teamInvite: {
      getAccess: "Krijg toegang tot jouw team",
      clickCode: "Klik op de code om te kopiëren",
      clickUrl: "Klik hier om te kopiëren",
      copied: "Gekopieerd",
      teamLink: "Team link",
      scanQr:
        "Scan de onderstaande qr-code om de teampagina in de Mingle-app te openen of gebruik",
      downLoadApp:
        "Download de Mingle Sport-app en gebruik de onderstaande teamcode om toegang te krijgen tot jouw team!",
    },

    createMatchTitle: "Plan je wedstrijd",
    createMatchBody: "Kies je tegenstander, locatie en tijd",
    nextMatchTitle: "Plan je wedstrijd",
    nextMatchBody: "Plen je eerste wedstrijd op Mingle Sport",
    matchCreateSuccessTitle: "Wedstrijd = gepland!",
    matchCreateSuccessBody: "Wat kun je doen met wedstrijden op Mingle Sport? ",
    matchCreateSuccessBody1: "Weet wie er komen",
    matchCreateSuccessBody2: "Regel vervoer",
    matchCreateSuccessBody3: "Maak je opstelling & houd de score bij",
    matchCreateSuccessBody4: "Deel met je volgers",
    matchCreateSuccessBody5: "Stem op verschillende MVP's",
    buttonCreateAnotherMatch: "Plan nog een wedstrijd",
    trainingHowOftenTitle: "Training",
    trainingHowOftenBody: "Hoe vaak heb je trainingssessies met je team??",
    trainingCreateSuccessTitle: "Training = gepland!",
    trainingCreateSuccessBody:
      "Wat kun je doen met trainingen op Mingle Sport? ",
    trainingCreateSuccessBody1: "Weet wie er komen",
    trainingCreateSuccessBody2:
      "Begrijp de redenen voor als iemand niet komt opdagen",
    trainingCreateSuccessBody3: "Spot aanwezigheid trends",
    trainingCreateSuccessBody4: "Motiveer met rewards",
    buttonCreateAnotherTraining: "Plan nog een training",

    downloadTrialTitle: "Download de app",
    downloadTrialBody:
      "Wanneer spelers zich aansluiten bij het team, dan kunnen ze hun naam claimen. Je kunt ze ook niet claimen.",

    teamLeagueTitle: "Selecteer jouw competitie",
    teamLeagueBody: "Alleen voor landen met verschillende competities.",
    enterTeamTitle: "Teamnaam",
    organizationTitle: "Zoek jouw club",
    successTitle: "Jouw team is succesvol aangemaakt 😄",
    successTitleHomeBase: "",
    rolePlayerTitle: "Speler",
    rolePlayerBody: "voor een team, elke positie mogelijk",
    roleFanTitle: "Fan",
    roleFanBody: "volg je favoriete team of speler",
    roleParentTitle: "Ouder",
    roleParentBody: "of andere betrokkene bij een team",
    roleTrainerTitle: "Team begeleider",
    roleTrainerBody: "eg, trainer, coach of team manager.",
    buttonContinue: "Ga verder",
    buttonRequestLeague: "Vraag nieuwe aan +",
    buttonNamePlaceHolder: "Je naam",
    buttonLastNamePlaceHolder: "Je achternaam",
    genderMaleLabel: "Man",
    genderFemaleLabel: "Vrouw",
    genderOtherLabel: "Andere",
    genderMixedLabel: "Mixed",
    sportTypeFootball: "Voetbal",
    sportTypeFutsal: "Zaalvoetbal",
    selectCountryLabel: "Selecteer land",
    subscriptionTitle: "Basic of Elevate?",
    subscriptionBody: "Kies je Team Boost Level",
    subscriptionBasicBody: "100% gratis. Geen advertenties",
    subscriptionEssentialsBody: "Level up your team",
    subscriptionAdvancedBody: "Track performance like a pro",
  },

  teamsPage: {
    title: "Team",
    buttonShareTeamLink: "Deel team link",
    modalShareText: "Deel team link",
    modalShareTeamPageText:
      "Check out de team pagina van {{teamName}} voor uitslagen, teamvorm & komende matches.",
    tabMembers: "Leden",
    tabOpponents: "Tegenstanders",
    buttonAddMemberLink: "Nieuw lid +",
    buttonAddOpponentLink: "Nieuwe tegenstander toevoegen",
    headerTableInfo: "Info",
    headerTableRole: "Rol",
    headerTablePosition: "Positie",
    headerTableStatus: "Status",
    headerTableMatchesPlayed: "Wedstrijden gespeeld",
    headerTableMatchesPlayedMobile: "Wedstrijden",
    noTeamFound: "Homebase is voor teams",
    noTeamFoundBody1:
      "Creëer een nieuw team of sluit je aan bij een team in de Mingle app.",
    noTeamFoundBody2:
      "Homebase is een desktop app for Mingle Sport power users. Meer stats, media en team management opties.",
    noTeamFoundLinkLabel: "Creëer je team",
    selectATeam: "Selecteer een team",
    buttonLabelInvite: "Nodig uit",
    buttonEditLabel: "Bewerk",
    buttonRemoveLabel: "Verwijder",
    buttonModalRemoveLabel: "Verwijder van team",
    buttonModalRemoveOpponentLabel: "Verwijder tegenstander",
    buttonModalCancelLabel: "Annuleer",
    modalRemoveTitle:
      "Weet je zeker dat je {{name}} wilt verwijderen van het team?",
    modalSubTitle:
      "Het verwijderen van {{name}} van het team heeft gevolgen voor zijn/haar statistieken.",

    modalRemoveOpponentTitle: "Verwijder tegenstander?",
    modalDeleteOpponentSubTitle:
      "Weet je zeker dat je tegenstander {{name}} wilt verwijderen?",

    modalShareButtonlabel: "Deel team link",

    editMemberModal: {
      title: "Wijzig lid",
      introBody: "Je kunt de rol van het teamlid wijzigen.",

      buttonEditMember: "Bewaar",
      buttonCancel: "Annuleer",
    },

    editPreUserModal: {
      title: "Wijzig naam en email",
      introBody:
        "Je kunt de velden van de pre-user wijzigen, dit betekent dat hij/zij dit profiel kan claimen wanneer hij/zij zich aanmeldt bij Mingle Sport.",
      labelName: "Naam *",
      labelEmail: "Email",
      sendInviteLabel: "Stuur uitnodiging email?",
      placeholderInputName: "John Doe",
      placeholderInputEmail: "E-mail adres (niet verplicht)",
      buttonEditMember: "Wijzig gegevens",
      buttonCancel: "Annuleer",
    },
    addMemberModal: {
      title: "Nieuw lid toevoegen",
      introBody:
        "Deze gebruiker wordt toegevoegd als pre-user, dit betekent dat hij/zij dit profiel kan claimen wanneer hij/zij zich aanmeldt bij Mingle Sport. Vul de gegevens in en klik op 'Lid toevoegen'.",
      labelName: "Naam *",
      labelEmail: "Email",
      placeholderInputName: "John Doe",
      placeholderInputEmail: "E-mail adres (niet verplicht)",
      buttonAddMember: "Lid toevoegen",
      buttonCancel: "Annuleer",
    },
    inviteMemberModal: {
      title: "Nodig lid uit",
      introBody:
        "Stuur een uitnodiging email naar dit lid zodat hij/zij dit profiel kan claimen wanneer hij/zij zich aanmeldt bij Mingle Sport",
      labelEmail: "E-mail adres",
      placeholderInputEmail: "Email adres",
      buttonSendInvite: "Stuur uitnodiging email",
      buttonCancel: "Annuleer",
    },
  },
  transferPages: {
    title: "Seizoen transfer",
    available: "beschikbaar",
    titleIntro: "Transfer je team naar het volgende seizoen",
    titleSubIntro: "Maak een nieuw team voor het volgende seizoen",
    titleSubSubIntro:
      "(Je huidige team en seizoen blijven bestaan en beschikbaar)",
    setupYourNewTeam: "Stel je nieuwe team samen voor het volgende seizoen",
    labelTransferTeam: "Transfer Team",
    alreadyTransferred: "Het team is al overgezet naar het nieuwe seizoen",
    stepTitle1: "Team info",
    stepTitle2: "Beheer leden",
    stepTitle3: "Informeer iedereen",
    unclaimed: "niet geclaimd",
    unclaimedTooltip:
      "Niet geclaimde users kun je niet meenemen naar een nieuw team.",
    labelLearnMore: "Meer weten?",
    teamNameAndLogo: "Team naam & logo",
    forNextSeason: "voor het volgende seizoen",
    labelPrevious: "Vorige",
    labelNext: "Volgende",
    labelMembersSelected: " Leden geselecteerd",
    labelSaveTeam: "Bewaar team",
    labelSelectAll: "Selecteer alles?",
    labelSetupTeam: "Stel team samen",
    inviteMemberInfo:
      "Je kunt nieuwe {{role}} uitnodigen via de uitnodigingslink die beschikbaar komt wanneer het team is aangemaakt",
    inviteMemberPlayerInfo:
      "Nodig nieuwe spelers uit via de uitnodiging link of voeg ze toe op de manage team pagina.",

    transferSucces: "Seizoenstransfer succesvol",
    inviteMembers: "Nodig nieuwe leden uit",
    scanQr:
      "Scan de QR-code om bij het team aan te sluiten, voer de onderstaande code in of deel de link",
    shareInviteLink: "Uitnodigingslink delen",
    labelDone: "Klaar",
  },
  mediaPage: {
    title: "Media",
    SubTitle: "Binnenkort beschikbaar!",
    body1:
      "Dit onderdeel is nog niet beschikbaar. We houden je op de hoogte wanneer je er gebruik van kunt maken!",
    body2:
      "Met media kun je alle media bekijken die is geupload en je eigen beelden uploaden. Je kunt de beelden bekijken en bespreken met het team.",
    buttonLabelUpdated: "Houd mij op de hoogte!",
  },
  schedulingPage: {
    title: "Schedule",
    SubTitle: "Binnenkort beschikbaar!",
    body1:
      "Dit onderdeel is nog niet beschikbaar. We houden je op de hoogte wanneer je er gebruik van kunt maken!",
    body2:
      "Met de agenda kun je alle wedstrijden en trainingen bekijken en beheren. Je kunt de agenda delen met het team en je eigen agenda synchroniseren.",
    buttonLabelUpdated: "Houd mij op de hoogte!",
  },
  statisticsPage: {
    title: "Statistieken",
    SubTitle: "Binnenkort beschikbaar!",
    body1:
      "Dit onderdeel is nog niet beschikbaar. We houden je op de hoogte wanneer je er gebruik van kunt maken!",
    body2:
      "Met statistieken kun je alle statistieken bekijken en beheren. Je kunt de statistieken delen met het team en je eigen statistieken synchroniseren.",
    buttonLabelUpdated: "Houd mij op de hoogte!",

    noStatsFound: "Geeen statistieken gevonden",
  },
  settingsPage: {
    title: "Team instellingen",
    titleOrganization: "Organisatie",
    titleLocation: "Locatie",
    buttonSave: "Bewaar",
    teamDetailsTitle: "Team details",
    changImageLabel: "Opslaan",
    changeAvatarTitle: "Team logo",
    changeAvatarSubTitle: "Het logo van je team welke in Mingle gebruikt wordt",

    changImageTitle: "Wijzig team logo",
    labelDropZone: "Sleep nieuwe afbeelding hier of klik om te uploaden",
    labelDropZoneOnlyOne: "(1 JPG of PNG bestand)",
    labelNewImageDropZone: "Nieuwe afbeelding",
    labelDropZoneErrorSize: "Bestand ({{size}}mb) is te groot , limiet is 4mb`",
    labelOrganization: "Organisatie",
    labelName: "Team naam",
    labelClubName: "Club naam",
    labelNameAbbreviation: "Team afkorting",
    archiveTeamTitle: "Archiveer team",
    archiveTeamSubTitle: "Seizoen voorbij? Archiveer je team.",
    archiveTeamButtonLabel: "Archiveer team",
    archiveTeamModalTitle: "Archiveer team?",
    archiveTeamModalSubTitle1:
      "Weet je zeker dat je onderstaand wilt archiveren?`",
    archiveTeamModalSubTitle2:
      "Je kunt dan geen trainingen en wedstrijden meer aanmaken. Stats blijven beschikbaar en je kunt het team altijd weer activeren in de instellingen.",
    archiveTeamModalButtonLabel: "Archiveer team",
    unArchiveTeamTitle: "Team activeren ",
    unArchiveTeamSubTitle: "Team opnieuw activeren.",
    unArchiveTeamButtonLabel: "Team activeren ",
    unArchiveTeamModalTitle: "Team activeren?",
    unArchiveTeamModalSubTitle1:
      "Weet je zeker dat je onderstaand team wilt activeren?",
    unArchiveTeamModalSubTitle2:
      "Je kunt dan weer trainingen en wedstrijden aanmaken. Stats blijven beschikbaar en je kunt het team altijd weer archiveren.",
    unArchiveTeamModalButtonLabel: "Team activeren",
    labelLeague: "Competitie",
    labelSeason: "Seizoen naam",
    labelSeasonSingle: "Seizoen",
    labelSeasonDate: "Seizoen periode",
    teamProfileTitle: "Team profiel",
    teamProfileSubTitle: "Pas de team details aan",
    labelTeamGender: "Geslacht",
    labelTeamAgeGroup: "Leeftijdsgroep",
    labelTeamLevel: "Team niveau",
    labelSelectTeamGender: "Selecteer geslacht",
    labelSelectTeamAgeGroup: "Selecteer leeftijdsgroep",
    labelSelectTeamLevel: "Selecteer team niveau",
    genderMaleLabel: "Man",
    genderFemaleLabel: "Vrouw",
    genderOtherLabel: "Anders",
    genderMixedLabel: "Mixed",
    teamSocialsTitle: "Team Socials",
    teamSocialsSubTitle: "Voeg social media links toe",
    teamAvatarTitle: "Team avatar",
    teamAvatarSubTitle: "De avatar afbeelding welke wordt gebruikt in Mingle",
    locationTitle: "Locatie",
    modalLocationTitle: "Locatie",
    modalLocationSubtitle: "Zoek & selecteer de locatie",
    modalLocationInputPlaceHolder: "Start met typen om de locatie te vinden.",
    modalEditLocationLabel: "Gebruik locatie",
    locationSubTitle: "Thuis locatie van dit team",
    noLocationTitle: "Onbekende locatie",
    noLocationSubTitle: "Er is nog geen locatie bekend voor dit team",
    discard: "Annuleer",
  },
  claimAccount: {
    startPage: {
      title: "Ben jij dit?",
      subTitle: "Claim dit account en sluit je aan bij het team",
      labelMatchesPlayed: "Wedstrijden gespeeld",
      labelGoalsScored: "Goals gescoord",
      labelAssistsGiven: "Assists gegeven",
      partOfTeam:
        "Je wordt lid van team <1>{{teamName}}</1> en je kunt Mingle Sport direct gaan gebruiken.",
      buttonLabel: "Registreer en claim je account",
      buttonLabelLoggedIn: "Claim account",
      notYourTeam: "Jij bent het niet maar het is wel je team?",
      joinTeam: "Wordt lid van het team",
    },

    successPage: {
      title: "Gefeliciteerd,  je hebt het account {{name}} geclaimd!",
      subTitle:
        "Je bent nu lid van team <2>{{teamName}}</2> en klaar om Mingle Sport te gebruiken.",
      downloadLabel: "Download de app",
      homebaseLabel: "Of bekijk de team details op:",
    },
  },
  shotQualityPage: {
    signUp: "Registreer",
    title: "Bekijk & download je video",
    detailsTitle: "Schot Kwaliteit",
    detailsAccuracy: "nauwkeurigheid",
    detailsSpeed: "snelheid",
    detailsPoints: "punten",
    signUpButtonLabel: "Registreer",
    downLoadButtonLabel: "Download video",
    downLoadAppButtonLabel: "Download de Mingle Sport app",
  },
  signUpPage: {
    title: "Registreer je bij Mingle Sport",
    subTitle: "Om het account te claimen en lid te worden van het team",
    haveAccount: "Heb je al een account?",
    buttonLabelSignIn: "Log in",
    labelInputFirstName: "Voornaam",
    labelInputLastName: "Achternaam",
    labelInputEmail: "Email",
    labelInputPassword: "Wachtwoord",
    labelButtonSignUp: "Claim & Registreer",
    placeholderInputFirstName: "Typ hier je voornaam ",
    placeholderInputLastName: "Type hier je achternaam",
    placeholderInputEmail: "Typ hier je email",
    placeholderInputPassword: "Typ hier je wachtwoord",
    labelCheckBoxPrivacyAndTerms:
      "Ik accepteer de Mingle Sport <2>Gebruikersvoorwaarden</2> en <6>Privacy Policy</6>.",
  },
  signInPage: {
    title: "Log in",
    subTitle: "Om het account te claimen en lid te worden van het team",
    dontHaveAccount: "Heb je nog geen Mingle Sport account?",
    buttonLabelSignUp: "Maak een account",
    labelInputEmail: "Email",
    labelInputPassword: "Wachtwoord",
    placeholderInputEmail: "Typ hier je email",
    placeholderInputPassword: "Typ hier je wachtwoord",
    labelButtonSignIn: "Claim &  Sign in",
  },
  statsPages: {
    titlePlayerPerformance: "Wedstrijd prestaties",
    titleMatchAttendance: "Wedstrijd aanwezigheid",
    titleReasonNotGoingAttendance: "Redenen van afwezigheid",
    subTitleReasonNotGoingAttendance:
      "Redenen voor afwezigheid van wedstrijden",
    titleTrainingReasonNotGoingAttendance: "Training afwezigheid",
    subTitleTrainingReasonNotGoingAttendance:
      "Redenen voor afwezigheid van trainingen",
    titleTrainingAttendance: "Training aanwezigheid",
    titleAverageAttendance: "Gemiddelde opkomst",
    buttonMoreInfo: "Meer info",
    buttonAllPlayers: "Alle spelers",
    titleTeamStats: "Team stats",
    titleMinutesPlayed: "Minuten gespeeld",
    subTitleMinutesPlayed: "Gemiddeld aantal minuten gespeeld per wedstrijd",
    titleGoals: "Doelpunten",
    titleGoalsScored: "Doelpunten gescoord",
    titleDistanceCovered: "Afstand afgelegd",
    subTitleDistanceCovered: "Total distance covered per player",
    titleAwards: "Awards",
    titleMatchAwards: "Wedstrijd Awards",
    titleAssists: "Assists",
    labelChangeMinutesPlayedSaved: "Bewaar",
    titleTeamPerformance: "Team performance",
    matchRating: "wedstrijd rating",
    titleSpectators: "Toeschouwers",
    titleEngagement: "Betrokkenheid",
    titleCheersComments: "Cheers & commentaar",
    titleGoalDifference: "Doelsaldo",
    totalScoredConceded:
      "In totaal werder er {{totalScored}} doelpunten gescoord. En waren er {{totalConceded}} tegendoelpunten",
    titleMatchWinRatio: "Wedstrijdwinstverhouding",
    titleTotalShare: "Totaal aandeel gewonnen wedstrijden",
    titleTotalWin: "Winst",
    titleTotalDraw: "Gelijk",
    titleTotalLost: "Verloren",
    titleGoalsPerMatch: "Goals per wedstrijd",
    titleAveragePerMatch: "Gemiddeld aantal doelpunten per wedstrijd",
    titleTotalGoalsConceded: "Tegendoelpunten",
    titleAverageGoalsConceded: "Gemiddeld aantal tegendoelpunten per wedstrijd",
    titleCleanSheets: "Clean sheets",
    titleMatches0Conceded: "Aantal wedstrijden met 0 tegendoelpunten",
    titleTeamForm: "Teamvorm",
    titleResultsLastMatches: "Resultaten van de laatste wedstrijden",
    titleLast30days: "Laatste 30 dagen",
    titleLast12Months: "Laatste 12 maanden",
    titlePast: "Finished",
    titleFuture: "Upcoming",
    titlePastFutureMatches: "wedstrijden",
    titlePastFutureTrainings: "trainingen",
    titlePlayersLeaderboard:
      "Spelers die de meeste wedstrijden hebben gespeeld",
    titlePlayersLeaderboardTraining:
      "Spelers die bij de meeste trainingen aanwezig waren",
    titlePlayersLeaderboardMinutesPlayed:
      "Spelers die bij de meeste speelminuten hebben gemaakt",
    titleInsights: "Inzichten",
    titleLearningsForTeam: "Leerpunten voor het team",
    titleTrendLastTrainings: "Trend laatste 2 trainingen",
    title100AttendanceTraining: "100% opkomst op training",
    titleTrendLastMatch: "Trend laatste 2 wedstrijden",
    title100AttendanceMatch: "100% opkomst bij de wedstrijden",
    titleTeamSizeTraining: "Teamgrootte per training",
    titleTeamSizeMatch: "Teamgrootte per wedstrijd",
    graphTitleEngagement: "Betrokkenheid",
    graphTitleGoalDifference: "DS totaal",
    graphTitleGoalDifferencePerMatch: "DS wedstrijd",
    graphTitleSpectators: "Toeschouwers",
    graphTitleMatchRating: "Wedstrijdb rating",
    graphTitleAverageMatchRating: "Gemiddelde wedstrijd rating",
    graphTitleMatchAttendance: "Wedstrijd opkomst",
    graphTitleTrainingAttendance: "Training opkomst",
    tooltipAveragematchRating:
      "Gemiddelde wedstrijd rating in de geselecteerde periode",
    tooltipTotalViews:
      "Totaal aantal views in de geselecteerde periode (inclusief teamleden en de volgers van teamleden)",
    tooltipAverageMatchAttendance:
      "De gemiddelde wedstrijd opkomst van het team per wedstrijd",
    tooltipAverageTrainingAttendance:
      "De gemiddelde trainings opkomst van het team per training",
    tooltipTotalEngagement:
      "Totale betrokkenheid (totaal van cheers en reacties) in de geselecteerde periode (inclusief teamleden en de volgers van teamleden)",
  },
  errorMessages: {
    errorCodeLogin:
      "De link is niet (meer) geldig, klik opnieuw op de link in de app om automatisch in te loggen. Of log in met je email en wachtwoord.",
    errorServerSignIn:
      "Kon niet inloggen met de gegevens die je hebt ingevuld, probeer het opnieuw. Of vraag een nieuw wachtwoord aan door op de 'Wachtwoord vergeten?' link hierboven te klikken.",
    errorServer:
      "Er is iets misgegaan, probeer het opnieuw. Als het probleem zich blijft voordoen, neem dan contact op met de support.",
    emailValid: "Email is niet geldig",
    emailUsernameValid:
      "Gebruikersnaam is niet geldig, het moet een email zijn",
    passwordValid: "Wachtwoord is niet geldig.",
    emailRequired: "Email is verplicht",
    fieldRequired: "Dit veld is verplicht",
    zipCode: "Postcode is niet geldig",
    agreeToTerms: "Je moet akkoord gaan met de algemene voorwaarden",
    nameRequired: "Naam is verplicht",
    firstNameRequired: "Naam is verplicht",
    lastNameRequired: "Achternaam is verplicht",
    genderRequired: "Geslacht is verplicht",
    teamNameRequired: "Team naam is verplicht",
  },
  schedulePages: {
    title: "Schedule",
    createMatch: "Maak wedstrijd aan",
    edit: "Bewerk",
    editMatch: "Bewerk wedstrijd",
    createMatchFirst:
      "Maak je wedstrijd aan en plan deze voor het gehele seizoen",
    createTraining: "Maak training aan",
    editTraining: "Bewerk training",
    createTrainingFirst:
      "Maak je training aan en plan deze voor het gehele seizoen",
    matchLabel: "Wedstrijd",
    trainingLabel: "Training",
    startTime: "Start tijd",
    duration: "Duur",
    date: "Datum",
    info: "Info",
    labelOpponent: "Tegenstander",
    labelOpponentName: "Naam Tegenstander",
    labelOpponentNameAbbreviation: "Tegenstander afkorting",
    occurrence: "Herhaling?",
    arrivalTime: "Aankomst tijd",
    labelAway: "Uit",
    labelHome: "Thuis",
    labelAwayMatch: "Uitwedstrijd",
    labelHomeMatch: "Thuiswedstrijd",
    thirtyMinutes: "30 min.",
    fourtyFiveMinutes: "45 min.",
    oneHour: "1 uur",
    oneHalfHour: "1,5 uur",
    twoHours: "2 uur",
    Never: "Eenmalig",
    Week: "Herhaal wekelijks",
    TwoWeeks: "Herhaal elke 2 weken",
    Month: "Herhaal elke maand",
    nextMatch: "Volgende wedstrijd",
    nextMatchExample: "Volgende wedstrijd (voorbeeld)",
    nextMatchInfo: "  Datum, thuiswedstrijd",

    nextTraining: "Volgende training",
    nextTrainingExample: "Volgende training (voorbeeld)",
    nextTrainingInfo: "Datum, Dinsdag training",

    blockTitleHomeAway: "Is de wedstrijd thuis of uit?",
    blockTitleTeamOpponent: "Team & tegenstander",
    blockTitleTimeDate: "Datum & tijd",
    blockTitleTrainingDetails: "Training titel",
    blockTitleTeam: "Team",
    modalOpponentTitle: "Voeg tegenstander toe",
    modalEditOpponentTitle: "Bewerk tegenstander",
    modalOpponentSubTitle:
      "Na het toevoegen kun je de tegenstander toevoegen aan de wedstrijd",

    modalDeleteMatchTitle: "Wedstrijd verwijderen?",
    modalDeleteMatchSubTitle:
      "Weet je zeker dat je deze wedstrijd wilt verwijderen?",
    modalDeleteTrainingTitle: "Training verwijderen?",
    modalDeleteTrainingSubTitle:
      "Weet je zeker dat je deze training wilt verwijderen?",
    modalDeleteTrainingSeriesTitle:
      "Wil je de hele serie verwijderen of alleen deze training?",
    modalEditTrainingTitle: "Bewerk training?",
    modalEditTrainingSeriesTitle:
      "Wil je de hele serie bewerken of alleen deze training?",
    modalDeleteLabelSingle: "Alleen deze",
    modalDeleteLabelSeries: "Hele serie",
    modalDeleteLabelCancel: "Annuleer",
    modalDeleteLabelDelete: "Verwijder",
    modalAddOpponentButtonLabel: "Voeg toe",
    modalEditOpponentButtonLabel: "Bewerk",
    autoCompleteOrganization: "Zoek en selecteer organisatie (club)",
    autoCompleteSearch: "Zoek",
    trainingTitleValidationError: "Training titel is een verplicht veld",
    matchOpponentNameValidationError: "Naam is een verplicht veld",
    matchOpponentAbbreviationValidationError: "Afkorting is required",
    organizationValidationError: "Organisatie is een verplicht veld",

    labelOpponentPlaceholder: "Typ de team naam van de tegenstander",
    labelOpponentNameAbbreviationPlaceholder:
      "Typ de afkorting van de tegenstander",
    newOpponentTooltip: "Voeg nieuwe tegenstander toe",
    selectOpponent: "Selecteer tegenstander",
    selectOpponentError: "Selecteer een tegenstander",
    note: "Opmerking",
    noteLabel: "Voeg opmerking toe",
    notePlaceholder: "Bijv. verzamelen bij de club",
  },
  editUserModal: {
    title: "Bewerk profiel",
    introBody: "Update je profiel gegevens",
    labelName: "Voornaam",
    labelLastName: "Acternaam",
    labelEmail: "Email adres",
    accountRole: "Account role",
    labelPreferredPosition: "Favoriete positie",
    labelDateOfBirth: "Geboortedatum",
    buttonEditMember: "Bewaar",
    buttonModalCancelLabel: "Annuleer",
    labelGender: "Geslacht",
  },
  confirmProceedModal: {
    areYouSure: "Weet je zeker dat je de pagina wil verlaten?",
    areYouSureBody: "Er zijn updates gemaakt welke nog niet zijn opgeslagen.",
    labelYes: "Ja, go!",
    labelNo: "Nee, blijf & bewaar!",
  },
  toastMessages: {
    toastTeamTransSuccess:
      "Team is succesvol overgezet naar het nieuwe seizoen.",
    toastTeamUpdateSuccess: "Team is succesvol gewijzigd.",
    toastUnArchiveSuccess: "Team is succesvol geactiveerd.",
    toastAttenadanceSuccess: "Aanwezigheid is succesvol gewijzigd.",
    toastSwitchSuccess: "Actief team is succesvol gewijzigd.",
    toastArchiveSuccess: "Team is succesvol gearchiveerd.",
    toastMessageOpponentAddedTitle: "Tegenstander toegevoegd",
    toastMessageOpponentAddedSubTitle: "Tegenstander is succesvol toegevoegd.",
    toastMessageMatchAddedTitle: "Wedstrijd aangemaakt",
    toastMessageMatchAddedSubTitle: "De wedstrijd is succesvol aangemaakt.",
    toastMessageMatchEditedTitle: "Wedstrijd aangepast",
    toastMessageMatchEditedSubTitle: "De wedstrijd is succesvol aangepast",
    toastMessageMatchDeletedTitle: "Wedstrijd verwijderd",
    toastMessageMatchDeletedSubTitle: "De wedstrijd is succesvol verwijderd.",
    toastMessageTrainingEditedTitle: "Training aangepast",
    toastMessageTrainingEditedSubTitle: "De Training is succesvol aangepast",
    toastMessageTrainingAddedTitle: "Training aangemaakt",
    toastMessageTrainingAddedSubTitle: "De training is succesvol aangemaakt.",
    toastMessageTrainingDeletedTitle: "Training verwijderd",
    toastMessageTrainingDeletedSubTitle: "De training is succesvol verwijderd.",
    toastDeletedSuccess: "Team lid {{name}} is succesvol verwijderd.",
    toastAddedSuccess: "{{name}} is succesvol toegevoegd aan het team.",
    toastEditSuccess: "{{name}} is succesvol gewijzigd.",
    toastDeletedOpponentSuccess:
      "Tegenstander {{name}} is succesvol verwijderd.",
    toastInviteSuccess: "Een uitnodigings email is verstuurd naar {{email}}.",
    toastSuccess: "Bedankt voor je interesse! We houden je op de hoogte.",
    toastMessageMinutesPlayedUpdated:
      "Gespeelde minuten zijn succesvol bijgewerkt",
  },
};
