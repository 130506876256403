import { useEffect, useState } from "react";
import { FlashMessage } from "~/sessions/FlashMessageSession.server";

export const FlashMessageToast = ({
  flashMessage,
}: {
  flashMessage: FlashMessage | null;
}) => {
  const [showFlashMessage, setShowFlashMessage] = useState<boolean>(false);
  useEffect(() => {
    if (flashMessage?.completed) {
      setShowFlashMessage(true);
    }
    const modalActions = {
      editOpponent: "modalEditOpponent",
      addOpponent: "modalAddOpponent",
      deleteOpponent: "modalDeleteOpponent",
      deleteTraining: "modalDeleteTraining",
      deleteMatch: "modalDeleteMatch",
      deleteUser: "modalDeleteMember",
      addUser: "modalAddMember",
      editMember: "modalEditMember",
      editPreUser: "modalEditPreUser",
      inviteUser: "modalInvitePreUser",
      archiveTeam: "modalArchiveTeamConfirm",
      unArchiveTeam: "modalUnArchiveTeamConfirm",
      editUser: "modalEditUser",
    };

    if (flashMessage?._action && modalActions[flashMessage._action]) {
      window[modalActions[flashMessage._action]]?.close();
    }

    setTimeout(() => {
      setShowFlashMessage(false);
    }, 3000);
  }, [flashMessage]);

  return (
    <div
      className={`toast toast-center toast-bottom z-50 max-w-[1024px]  w-full ${
        showFlashMessage && flashMessage?.message ? "block" : "hidden"
      }`}
    >
      <div className="alert alert-success ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <rect width="24" height="24" rx="4.5" fill="#228B77" />
          <path
            d="M17.4738 8.12012L17.4651 8.10887L17.4556 8.09831C17.188 7.8014 16.7429 7.80057 16.4743 8.09582L10.4392 14.5903L7.54252 11.4651C7.27394 11.1696 6.82866 11.1704 6.561 11.4674C6.313 11.7426 6.313 12.1675 6.561 12.4427L6.56097 12.4427L6.56453 12.4466L9.65054 15.7761C9.85832 16.0054 10.1464 16.125 10.4219 16.125C10.7193 16.125 10.9888 16.0016 11.193 15.7762L17.4122 9.08371C17.6875 8.8084 17.682 8.38961 17.4738 8.12012Z"
            fill="white"
            stroke="white"
            strokeWidth="0.75"
          />
        </svg>
        <div className="flex flex-col">
          <h3 className="font-semibold">
            {flashMessage?.message && <>{flashMessage?.message}</>}
          </h3>
          {flashMessage?.bodyText && <p>{flashMessage?.bodyText}</p>}
        </div>
      </div>
    </div>
  );
};
