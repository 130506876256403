import { useBlocker, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { ModalConfirmProceed } from "~/components/modals/ModalConfirmProceed";
import { DialogWindow } from "~/utils/api.interfaces.enums";

declare let window: DialogWindow;
type Props = {
  formHasChanges: boolean;
  blockerState: (state: string) => void;
};

export default function BlockerModalCheck({ formHasChanges, blockerState }) {
  const navigate = useNavigate();
  const [nextLocation, setNextLocation] = useState<string>("");
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    setNextLocation(nextLocation.pathname);
    return formHasChanges && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (blocker.state === "blocked") {
      blockerState(blocker.state);
      window.modalConfirmProceed.showModal();
    } else if (blocker.state === "proceeding") {
      blockerState(blocker.state);
      navigate(nextLocation);
    }
  }, [blocker]);
  useEffect(() => {
    const dialog = document.getElementById("modalConfirmProceed") as any;
    dialog.addEventListener("close", (event: CloseEvent) => {
      blockerState(blocker.state);
    });
  }, []);
  return (
    <>
      <ModalConfirmProceed blocker={blocker} />
    </>
  );
}
