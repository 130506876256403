import { useTranslation } from "react-i18next";
import { MixPanel } from "~/utils/MixPanel";
import { useTheme } from "~/utils/theme-provider";

type Props = {};

export function ModalWelcome({}: Props) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();

  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalWelcome">
        <div className="modal-box">
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("modalWelcome.modalWelcomeTitle")}
          </h3>

          <p className="text-center  mt-6">
            {t("modalWelcome.modalWelcomeBody")}
          </p>

          <div className="flex justify-center align-center gap-6 ">
            <div className="flex justify-center align-center gap-6 ">
              <a
                href="https://apps.apple.com/us/app/mingle-sport-grassroots-app/id1583591186"
                target="_blank"
              >
                <img
                  src="/download-app-store.svg"
                  className="lg:h-[60px] lg:w-auto h-[40px] mx-auto mt-4 "
                  onClick={() => {
                    MixPanel.track("signIn_tap_button_downloadIos");
                  }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=sport.mingle.android.app"
                target="_blank"
                onClick={() => {
                  MixPanel.track("signIn_tap_button_downloadAndroid");
                }}
              >
                <img
                  src="/google-play-badge.png"
                  className="lg:h-[60px] lg:w-auto h-[40px] mx-auto mt-4 mb-6"
                />
              </a>
            </div>
          </div>
          <form method="dialog" className="flex mt-6 w-full">
            <button className="btn btn-primary btn-outline m-auto min-w-28">
              Checkout Homebase
            </button>
          </form>
        </div>
      </dialog>
    </>
  );
}
