import { useNavigation } from "react-router";
import * as React from "react";
import { useEffect } from "react";

import { FileWithPath, useDropzone } from "react-dropzone-esm";

import { useTranslation } from "react-i18next";

interface UploadImageProps {
  maxFiles: number;
  onAcceptedFilesChange: (acceptedFiles: readonly FileWithPath[]) => void;
}

export const UploadTeamImage: React.FC<UploadImageProps> = ({
  maxFiles,

  onAcceptedFilesChange,
}) => {
  const { t, ready, i18n } = useTranslation("translation");
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { "image/*": [".png", ".jpg", ".jpeg"] },
      maxSize: 4000000,
      maxFiles: maxFiles,
      // validator: sizeValidator,
    });

  let navigation = useNavigation();
  let isSubmitting =
    navigation?.state === "submitting" || navigation.state === "loading";

  useEffect(() => {
    if (!isSubmitting) {
      acceptedFiles ? acceptedFiles.slice(0, 0) : 0;
    }
  }, [isSubmitting]);

  const acceptedFileItems = acceptedFiles.map((file: File) => {
    return (
      <div
        key={file.name}
        className="text-success flex flex-col gap-2 text-center mb-4"
      >
        <img src={URL.createObjectURL(file)} className={`ml-auto `} />
        {file.name}
      </div>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {(file.size / 1048576).toFixed(2)}mb
      <ul>
        {errors.map((e) => (
          <li key={e.code} className="text-error">
            {e.code === "file-too-large"
              ? `${t("settingsPage.labelDropZoneErrorSize", {
                  size: (file.size / 1048576).toFixed(2),
                })}`
              : e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  React.useEffect(() => {
    onAcceptedFilesChange(acceptedFiles);
  }, [acceptedFileItems]);

  return (
    <>
      {/*{acceptedFileItems.length > 0 && (*/}
      {/*  <>*/}
      {/*    <h2 className="text-gray-400 dark:text-gray-200 font-bold cursor-pointer text-center">*/}
      {/*      {t("settingsPage.labelNewImageDropZone")}*/}
      {/*    </h2>*/}
      {/*    {acceptedFileItems}*/}
      {/*  </>*/}
      {/*)}*/}

      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="btn btn-default btn-sm ml-auto">
          <img src="/images/icon-pencil.svg" alt="" className="w-4" />
          Update image
        </div>

        <ul>{fileRejectionItems}</ul>
      </div>
    </>
  );
};
export default UploadTeamImage;
