import { useNavigation } from "react-router";
import { ComponentPropsWithoutRef, useEffect } from "react";

//see https://mortenbarklund.com/blog/react-typescript-props-spread/
interface MingleInputProps extends ComponentPropsWithoutRef<"button"> {
  submitValue?: string;
  label: string;
  disabled?: boolean;
  theme?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}
export const MingleSubmitButton = ({
  submitValue,
  disabled,
  label,
  onClick,
  theme = "btn-primary",
  ...restProps
}: MingleInputProps) => {
  let navigation = useNavigation();
  let isSubmitting =
    (navigation?.state === "submitting" || navigation.state === "loading") &&
    navigation?.formData?.get("_action") === submitValue;
  // useEffect(() => {
  //   console.log(navigation?.state);
  //   console.log(navigation);
  // }, [navigation]);
  return (
    <button
      type="submit"
      value={submitValue}
      name="_action"
      disabled={isSubmitting || disabled}
      className={`btn w-full whitespace-nowrap flex flex-col px-4 ${theme}`}
      {...restProps}
      onClick={onClick}
    >
      <span>{label}</span>
      {isSubmitting && <span className="loading loading-spinner"></span>}
    </button>
  );
};
