import mixpanel from "mixpanel-browser";
import { DialogWindow } from "~/utils/api.interfaces.enums";

declare let window: DialogWindow;
if (!(typeof document === "undefined")) {
  mixpanel.init(window.ENV.MIXPANEL_PROJECT_TOKEN, {
    debug: window.ENV.ENVIRONMENT === "development",
  });
}

let actions = {
  identify: (id: string) => {
    mixpanel?.identify(id);
  },
  alias: (id: string) => {
    mixpanel?.alias(id);
  },
  track: (name: string, props?: any) => {
    mixpanel?.track(name, props);
  },
  people: {
    set: (props: any) => {
      mixpanel?.people.set(props);
    },
  },
};

export let MixPanel = actions;
