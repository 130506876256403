import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "~/utils/theme-provider";
import UploadTeamImage from "./UploadTeamImage";
import { FileWithPath } from "react-dropzone-esm";

type Props = {
  teamHeaderImageChange: (e: File) => void;
  teamHeaderChange: (e: boolean) => void;
};

export function ChangeHeaderImage({
  teamHeaderImageChange,
  teamHeaderChange,
}: Props) {
  const [newImage, setNewImage] = useState<readonly FileWithPath[]>([]);
  let fileUploadRef = useRef<HTMLInputElement>(null);
  const [theme] = useTheme();

  useEffect(() => {
    if (!fileUploadRef.current) return;

    const dataTransfer = new DataTransfer();
    newImage.forEach((file) => {
      dataTransfer.items.add(file);
      teamHeaderImageChange(file);
      teamHeaderChange(true);
    });

    fileUploadRef.current.files = dataTransfer.files;

    // Help Safari out
    if (fileUploadRef.current.webkitEntries.length) {
      fileUploadRef.current.dataset.file = `${dataTransfer.files[0].name}`;
    }
  }, [newImage]);
  return (
    <>
      <div className="ml-auto py-2">
        <UploadTeamImage
          maxFiles={1}
          onAcceptedFilesChange={(files) => {
            setNewImage(files);
          }}
        />
        <input
          type="file"
          className="hidden"
          name="imageFile"
          ref={fileUploadRef}
        />
      </div>
    </>
  );
}
