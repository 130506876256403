import { z } from "zod";
import { TFunctionNonStrict } from "i18next";

export function schemaSignInUser(t: TFunctionNonStrict<"translation">) {
  return z.object({
    email: z
      .string({ required_error: t("errorMessages.emailValid") || "" })
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z
      .string({ required_error: t("errorMessages.passwordValid") || "" })
      .min(2, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}
export function schemaSignUpUserToClaim(t: TFunctionNonStrict<"translation">) {
  return z.object({
    firstName: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.fieldRequired") || "",
      }),
    lastName: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .min(1, {
        message: t("errorMessages.fieldRequired") || "",
      }),
    agreeToTerms: z
      .string({ required_error: t("errorMessages.fieldRequired") || "" })
      .refine((val) => val, {
        message: t("errorMessages.agreeToTerms") || "",
      }),

    email: z
      .string({ required_error: t("errorMessages.emailValid") || "" })
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z
      .string({ required_error: t("errorMessages.passwordValid") || "" })
      .min(1, {
        message: t("errorMessages.passwordValid") || "",
      }),
  });
}

export async function validatorSignUpUser(
  t: TFunctionNonStrict<"translation">
) {
  return z.object({
    agreeToTerms: z.boolean().refine((val) => val === true, {
      message: t("errorMessages.agreeToTerms") || "",
    }),
    email: z
      .string()
      .min(1, {
        message: t("errorMessages.emailValid") || "",
      })
      .email(t("errorMessages.emailValid") || ""),
    password: z.string().min(1, {
      message: t("errorMessages.passwordValid") || "",
    }),
  });
}

export function schemaUpdateUser(t: TFunctionNonStrict<"translation">) {
  return z.object({
    firstName: z
      .string({ required_error: t("errorMessages.firstNameRequired") || "" })
      .min(1, {
        message: t("errorMessages.firstNameRequired") || "",
      }),
    lastName: z
      .string({ required_error: t("errorMessages.lastNameRequired") || "" })
      .min(1, {
        message: t("errorMessages.lastNameRequired") || "",
      }),
    gender: z
      .string({ required_error: t("errorMessages.genderRequired") || "" })
      .min(2, {
        message: t("errorMessages.genderRequired") || "",
      }),
  });
}
