import { useTranslation } from "react-i18next";
import { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";
import { ProgressUpDown } from "~/components/ProgressUpDown";
import { useEffect, useState } from "react";

export default function GoalsRowBlocks({
  matches,
  activeTeamWithStats,
  totals,
}: {
  matches: IMatchStats[];
  activeTeamWithStats?: ITeam;
  totals: any;
}) {
  const { t, ready, i18n } = useTranslation("translation");
  const [trendGoals, setTrendGoals] = useState<string>("up");
  useEffect(() => {
    if (!matches) return;
    if (matches?.length === 0) return;
    const averageGoalsPerMatch = totals?.totalScored / matches?.length;
    const goalsLastmatch = matches[matches?.length - 1]?.score?.team;
    setTrendGoals(averageGoalsPerMatch > goalsLastmatch ? "down" : "up");
  }, [matches]);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h2 className="font-bold text-base lg:text-2xl pb-0">
            {t("statsPages.titleGoalsPerMatch")}{" "}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-500">
            {t("statsPages.titleAveragePerMatch")}
          </h3>

          {totals?.totalScored > 0 && matches ? (
            <div className="flex justify-between items-center">
              <h4 className="text-3xl lg:text-5xl font-bold">
                {parseFloat(totals?.totalScored / matches?.length || 0).toFixed(
                  2
                )}
              </h4>
              <ProgressUpDown label={trendGoals} />
            </div>
          ) : (
            <>
              <h4 className="text-3xl lg:text-5xl font-bold">0</h4>
            </>
          )}
        </div>
      </div>
      <div className="card ">
        <div className="card-body">
          <h2 className="font-bold text-base lg:text-2xl pb-0">
            {t("statsPages.titleTotalGoalsConceded")}{" "}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-500">
            {t("statsPages.titleAverageGoalsConceded")}
          </h3>
          <h4 className="text-3xl lg:text-5xl font-bold">
            {parseFloat(totals?.totalConceded / matches?.length || 0).toFixed(
              2
            )}
          </h4>
        </div>
      </div>
      <div className="card ">
        <div className="card-body">
          <h2 className="font-bold text-base lg:text-2xl pb-0">
            {" "}
            {t("statsPages.titleCleanSheets")}{" "}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-500">
            {t("statsPages.titleMatches0Conceded")}
          </h3>
          <h4 className="text-3xl lg:text-5xl font-bold">
            {totals?.totalCleanSheet}
          </h4>
        </div>
      </div>
    </>
  );
}
