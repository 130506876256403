export interface MingleRadioButtonProps {
  label: string;
  disabled?: boolean;
  value: string;
  checked: boolean;
  name: string;
  showRadio?: boolean;
  isLarge?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MingleRadioButton = (props: MingleRadioButtonProps) => {
  return (
    <div className="form-control w-full ">
      <label
        className={`label cursor-pointer border dark:border-gray-900 rounded-lg flexgap-1 md:gap-2 lg:gap-3  xl:gap-4  ${
          props.isLarge ? "text-2xl font-[700] px-8 py-4 " : " px-4 py-2 "
        } ${props.checked ? " dark:bg-blue-900 border-primary " : ""}`}
      >
        <span>{props.label}</span>
        <input
          type="radio"
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          className={`radio radio-primary dark:radio-accent ${
            props.showRadio ? "" : "hidden"
          }`}
          defaultChecked={props.checked}
        />
      </label>
    </div>
  );
};

export default MingleRadioButton;
