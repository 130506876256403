import { useMatches } from "react-router";

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */

export const useMatchesData = <T,>(routeId: string): T => {
  const matches = useMatches();
  const data = matches?.find((match) => match.id === routeId)?.data;
  return data as T;
};
