import { useTranslation } from "react-i18next";

import { useTheme } from "~/utils/theme-provider";
import { ITeam } from "~/utils/api.interfaces.enums";

type Props = {
  activeTeam?: ITeam;
};

export function ModalTeamIsArchived({ activeTeam }: Props) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();

  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalTeamIsArchived">
        <div className="modal-box">
          <div className="text-primary m-auto dark:text-secondary flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="36"
              fill="none"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M38.887 12.339a2 2 0 0 1 2.774.548c.551.821 1.543 2.565 1.841 3.225.317.7.498 1.337.498 1.86v.056c0 .08-.005.158-.014.237L42 18.028l1.986.237v.007l-.002.007-.002.018-.006.044a5.257 5.257 0 0 1-.096.473 6.4 6.4 0 0 1-.37 1.057c-.302.7-.742 1.481-1.303 2.288-1.11 1.625-2.71 3.477-4.657 5.225l-.011.01C33.607 30.874 28.139 34 22 34c-1.71 0-3.375-.226-4.968-.623A2 2 0 0 1 18 29.496C19.304 29.82 20.644 30 22 30c4.792 0 9.349-2.471 12.883-5.597 1.727-1.552 3.11-3.167 4.025-4.507l.01-.015c.445-.638.747-1.192.924-1.605l.017-.038c.04-.09.072-.174.096-.245a3.83 3.83 0 0 0-.096-.23l-.002-.005c-.183-.405-1.056-1.955-1.518-2.644a2 2 0 0 1 .548-2.775ZM22 6C17.12 6 12.522 8.277 9.06 11.233c-1.708 1.456-3.046 2.995-3.946 4.363-.46.706-.75 1.283-.92 1.719a3.672 3.672 0 0 0-.164.511C4.003 17.938 4 17.994 4 18c0 .007.004.065.03.182.029.127.08.3.164.518.17.436.457 1.007.91 1.69.911 1.359 2.253 2.927 3.95 4.373.549.464 1.13.915 1.738 1.344a2 2 0 0 1-2.307 3.268 29.116 29.116 0 0 1-2.017-1.56l-.005-.005c-1.98-1.687-3.569-3.532-4.684-5.197l-.006-.007C.652 20.914 0 19.282 0 18c0-1.288.658-2.895 1.764-4.592l.005-.007c1.127-1.712 2.723-3.532 4.693-5.21C10.412 4.818 15.895 2 22 2c2.247 0 4.397.416 6.407 1.108 2.483.856 4.762 2.138 6.77 3.598a2 2 0 0 1-2.353 3.234c-1.75-1.273-3.68-2.347-5.72-3.05C25.457 6.324 23.747 6 22 6Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M43.561.75a2 2 0 0 1-.31 2.811l-28 22.427-12.004 9.575a2 2 0 1 1-2.494-3.126l11.997-9.571 28-22.427a2 2 0 0 1 2.811.31Z"
                clipRule="evenodd"
              />
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M22 14a4 4 0 0 0-2.982 6.666l-2.98 2.668a8 8 0 1 1 12.138-10.42l-3.087 2.545A3.988 3.988 0 0 0 22 14Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("titleModalTeamIsArchived")}
          </h3>

          <p className="text-center  mt-6">{t("bodyModalTeamIsArchived")}</p>
          <form method="dialog" className="flex mt-6 w-full">
            <button className="btn btn-primary btn-outline m-auto min-w-28">
              ok
            </button>
          </form>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen  backdrop-blur-sm"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
