import { useNavigation } from "react-router";
import * as React from "react";
import { useEffect } from "react";

import { FileWithPath, useDropzone } from "react-dropzone-esm";

import { useTranslation } from "react-i18next";

interface UploadImageProps {
  maxFiles: number;
  onAcceptedFilesChange: (acceptedFiles: readonly FileWithPath[]) => void;
  defaultImage?: React.ReactNode;
}

export const UploadNewImage: React.FC<UploadImageProps> = ({
  maxFiles,
  onAcceptedFilesChange,
  defaultImage,
}) => {
  const { t, ready, i18n } = useTranslation("translation");
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: { "image/*": [".png", ".jpg", ".jpeg"] },
      maxSize: 4000000,
      maxFiles: maxFiles,
      // validator: sizeValidator,
    });

  let navigation = useNavigation();
  let isSubmitting =
    (navigation?.state === "submitting" || navigation.state === "loading") &&
    navigation?.formData?.get("_action") === "addOpponent";

  useEffect(() => {
    if (!isSubmitting) {
      acceptedFiles ? acceptedFiles.slice(0, 0) : 0;
    }
  }, [isSubmitting]);

  const acceptedFileItems = acceptedFiles.map((file: File) => {
    return (
      <div key={file.name} className="text-success flex flex-col gap-2">
        <img
          src={URL.createObjectURL(file)}
          className={"rounded-full w-24 h-24 shadow-lg m-auto object-cover"}
        />
        {(file.size / 1048576).toFixed(2)}mb
      </div>
    );
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.name}>
      {file.name} - {(file.size / 1048576).toFixed(2)}mb
      <ul>
        {errors.map((e) => (
          <li key={e.code} className="text-error">
            {e.code === "file-too-large"
              ? `${t("settingsPage.labelDropZoneErrorSize", {
                  size: (file.size / 1048576).toFixed(2),
                })}`
              : e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  React.useEffect(() => {
    onAcceptedFilesChange(acceptedFiles);
  }, [acceptedFileItems]);

  return (
    <div {...getRootProps()} className="text-center cursor-pointer">
      <input {...getInputProps()} />
      {acceptedFiles.length > 0 ? (
        <>{acceptedFileItems}</>
      ) : (
        <>
          <div className="relative flex justify-center items-center w-36 h-36 rounded-full p-2 border-gray-900 border text-primary m-auto">
            {defaultImage}

            <div className="absolute bottom-0 right-0 bg-black text-white flex justify-center items-center w-10 h-10 rounded-lg p-2 border-gray-900 border  m-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
              >
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M16.91 3.09a1.524 1.524 0 0 0-2.154 0L4.062 13.784l-1.078 3.232 3.233-1.077L16.91 5.244a1.524 1.524 0 0 0 0-2.154ZM13.578 1.91a3.19 3.19 0 0 1 4.512 4.512L7.284 17.228a1 1 0 0 1-.391.242l-4.647 1.549a1 1 0 0 1-1.264-1.265l1.548-4.647a1 1 0 0 1 .242-.39L13.577 1.91Z"
                  clipRule="evenodd"
                />
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M6.91 16.423 3.578 13.09l1.179-1.18 3.333 3.334-1.178 1.179ZM16.077 7.256l-3.333-3.333 1.179-1.179 3.333 3.334-1.179 1.178Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          {/*<h2 className="text-gray-400 dark:text-gray-700 font-bold cursor-pointer mt-4">*/}
          {/*  {t("settingsPage.labelDropZone")}*/}
          {/*</h2>*/}
          {/*<p className="text-gray-400 dark:text-gray-700 text-sm">*/}
          {/*  {" "}*/}
          {/*  {t("settingsPage.labelDropZoneOnlyOne")}*/}
          {/*</p>*/}
        </>
      )}

      <ul>{fileRejectionItems}</ul>
    </div>
  );
};
export default UploadNewImage;
