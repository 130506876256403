import { Form, useNavigation } from "react-router";
import { useTranslation } from "react-i18next";
import { MixPanel } from "~/utils/MixPanel";
import { useMatchesData } from "~/utils/useMatchesData";
import { UserData } from "~/routes/_pages";
import React, { useEffect, useRef } from "react";

type Props = {};
export default function TeamDropDown({}) {
  const { teams, activeTeam } = useMatchesData<UserData>("routes/_pages");
  const { t, ready, i18n } = useTranslation("translation");
  let navigation = useNavigation();
  let isSubmitting =
    navigation.state === "submitting" || navigation.state === "loading";
  let teamDropdownRef = useRef<HTMLDetailsElement>(null);

  useEffect(() => {
    window.addEventListener("click", (e: MouseEvent) => {
      if (
        teamDropdownRef?.current &&
        !teamDropdownRef?.current?.contains(e.target as Node)
      ) {
        teamDropdownRef.current.open = false;
      }
    });
  }, []);
  return (
    <>
      <ul
        className={`${
          teams?.length > 1 ? "menu p-0" : "p-4 flex"
        } mb-2 z-20   bg-white/[.05] border-white/10 border rounded-lg w-full relative`}
      >
        <li>
          <details
            className={`${teams?.length > 1 ? "dropdown " : "relative"}`}
            ref={teamDropdownRef}
          >
            <summary
              tabIndex={0}
              className={`flex flex-col w-full gap-1 items-start after:absolute after:right-5 after:top-6 ${
                teams?.length > 0 ? "" : " cursor-default "
              } ${isSubmitting ? " cursor-not-allowed after:hidden" : ""} `}
            >
              {activeTeam ? (
                <>
                  <div
                    tabIndex={0}
                    className={`${
                      teams?.length > 1 ? "btn btn-link" : "cursor-default"
                    }  p-0 flex text-white font-normal no-underline text-base flex-nowrap w-full m-0`}
                  >
                    {activeTeam?.logoUrl ? (
                      <img
                        alt=""
                        src={activeTeam?.logoUrl}
                        className="h-8 w-8 rounded-full object-cover mr-auto"
                      />
                    ) : (
                      <div className="avatar placeholder  mr-auto">
                        <div className="bg-neutral-focus text-neutral-content rounded-full w-8 mr-auto">
                          <span className="text-md">
                            {activeTeam?.nameAbbreviation}
                          </span>
                        </div>
                      </div>
                    )}

                    {isSubmitting && (
                      <span className="loading loading-spinner loading-sm text-secondary z-30"></span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {teams?.length > 1 && (
                    <div
                      tabIndex={0}
                      className="btn btn-link m-0 h-auto w-full flex justify-between text-white font-normal no-underline text-base "
                    >
                      <div className="mr-auto">
                        {" "}
                        {t("teamsPage.selectATeam")}
                      </div>
                      <svg
                        className="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                      </svg>
                    </div>
                  )}
                </>
              )}
              <div>
                <div
                  className={`w-[200px] whitespace-nowrap text-left mr-auto overflow-ellipsis overflow-hidden font-[700]`}
                >
                  {activeTeam?.name}
                </div>
                {activeTeam?.organization?.type === "club" && (
                  <div
                    className={`w-[200px] whitespace-nowrap text-left mr-auto overflow-ellipsis overflow-hidden`}
                  >
                    {activeTeam?.organization?.name}
                  </div>
                )}
              </div>
              <div className={`w-full  flex justify-start items-center  gap-2`}>
                {activeTeam?.organization?.type === "club" && (
                  <div className="badge text-xs py-2">
                    {" "}
                    {activeTeam?.season?.name}
                  </div>
                )}

                {!activeTeam?.isArchived && (
                  <>
                    {" "}
                    {activeTeam?.boostStatus?.boostLevel > 0 ? (
                      <div className="badge text-xs bg-black py-2 text-[#FAC99E] border-[#FAC99E]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                        >
                          <path
                            fill="#FAC99E"
                            fillRule="evenodd"
                            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        level {activeTeam?.boostStatus?.boostLevel}
                      </div>
                    ) : (
                      <div className="badge text-xs  bg-white/10 py-2 text-white border-transparent">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                        >
                          <path
                            fill="#974DDE"
                            fillRule="evenodd"
                            d="M10.342 1.857c.537-.54 1.439-.026 1.255.714l-1.09 4.382h3.056c.65 0 .991.778.551 1.26l-7.206 7.905c-.517.567-1.448.08-1.282-.672l1.122-5.073h-3.07c-.666 0-1-.81-.529-1.285l7.193-7.231Z"
                            clipRule="evenodd"
                          />
                        </svg>
                        level 0
                      </div>
                    )}
                  </>
                )}
                {activeTeam?.isArchived && (
                  <div className="badge badge-sm py-2  badge-outline text-gray-400">
                    {" "}
                    archived
                  </div>
                )}
              </div>
            </summary>
            {teams?.length > 1 && (
              <ul
                tabIndex={0}
                className="dropdown-content menu shadow bg-base-100 rounded-box w-full z-20 ml-0"
              >
                {teams?.map((team: any) => {
                  return (
                    <li
                      key={team.id}
                      className=" "
                      onClick={() => {
                        if (document.activeElement instanceof HTMLElement) {
                          teamDropdownRef.current.open = false;
                          window.sidebar.close();
                        }
                      }}
                    >
                      <Form
                        method="post"
                        action="/action/changeteam"
                        reloadDocument={false}
                        className="text-left p-0 flex flex-row w-full"
                      >
                        <button
                          className="flex gap-2 items-center w-full text-left px-2 py-3"
                          type={"submit"}
                          name={"teamId"}
                          value={team?.id}
                          onClick={(e) => {
                            MixPanel.track("mft_common_tap_item_selectTeam");
                          }}
                        >
                          {team?.logoUrl ? (
                            <img
                              alt=""
                              src={team?.logoUrl}
                              className="h-5 w-5 rounded-full object-cover"
                            />
                          ) : (
                            <div className="avatar placeholder">
                              <div className="bg-neutral-focus text-neutral-content rounded-full w-5">
                                <span className="text-sm">
                                  {team?.nameAbbreviation}
                                </span>
                              </div>
                            </div>
                          )}
                          {team.name}
                        </button>
                        {team?.organization?.type === "club" && (
                          <div className="badge badge-sm mr-1">
                            {team?.season?.name}
                          </div>
                        )}
                      </Form>
                    </li>
                  );
                })}
              </ul>
            )}
          </details>
        </li>
      </ul>
    </>
  );
}
